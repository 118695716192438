// UpTime.js
import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button, Heading, Box, Tip } from 'grommet';
import { ShareOption } from 'grommet-icons';
import Iframe from 'react-iframe';

function goNav(url) {
  window.open(url, '_blank').focus();
}

const UpTimeComp = () => {
//  const size = useContext(ResponsiveContext);
//  const year = new Date().getFullYear();

  return (
    <Card height="400px">
      <CardHeader pad={{ horizontal: 'medium', top: 'small', bottom: 'none' }}>
        <Box>
          <Heading margin="none" level={2} pad="none">
            BRIM availability
          </Heading>
        </Box>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', top: 'none', bottom: 'small' }} align="start" fill>
        <Iframe url="https://chart-embed.service.newrelic.com/herald/c8b86748-16e3-4cdb-9fc6-5e4abd101330?timepicker=true"
          width="100%" 
          height="100%"
          frameBorder="0"
          overflow="visible"
          allowFullScreen
          scrolling="no"
          id=""
          className=""/>
        <Tip content="View full observability...">
          <Button label="View details" 
                  onClick={() => { goNav("https://onenr.io/0dQe39xaNRe");}}
                  secondary/>
        </Tip>
      </CardBody>
      <CardFooter pad={{horizontal: "small"}} background="light-2">
        <Tip content="Share with colleagues...">
          <Button icon={<ShareOption color="plain" />} 
                  href="mailto:?subject=EaaS Ops site...&body=https://eaas-ops.it.hpe.com"
                  hoverIndicator />
        </Tip>
      </CardFooter>
    </Card>
  );
};

export { UpTimeComp as UpTime };
