import React from 'react';
//import styled from 'styled-components';
import { Page, PageContent, PageHeader, Footer, Text } from 'grommet';
//import { Hpe as HpeIcon } from 'grommet-icons';
import Iframe from 'react-iframe';
import { AppFooter } from '../components';

const SaaSPage = () => {

  //const [value, setValue] = React.useState('');
  //const size = useContext(ResponsiveContext);
  //const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);


  return (

    <Page background="background-back" flex="grow">

      <PageContent height="100%">
        <PageHeader title="SaaS Value Chain" size="small"
          pad={{ vertical: 'small' }}
          subtitle={`Your one stop shop to understand E2E SaaS operations.`}
        />

        <Iframe url="https://p.newrelic.com/d/aecfcf95-1d75-4288-b5a2-929175526c95"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          id=""
          className=""/>

      </PageContent>

      <Footer pad="xsmall" justify="center">
        <Text size="11pt" style={{fontStyle: 'italic', fontWeight: 'bold'}}>Powered by ITaaS</Text>
      </Footer>

      <AppFooter />

    </Page>

  );
};

export { SaaSPage as SaaS };