// UserSessions.js
import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button, Heading, Box, Tip } from 'grommet';
import { ShareOption } from 'grommet-icons';
import Iframe from 'react-iframe';

function goNav(url) {
  window.open(url, '_blank').focus();
}

const UserSessionsComp = () => {
//  const size = useContext(ResponsiveContext);
//  const year = new Date().getFullYear();


  return (
    <Card height="400px">
      <CardHeader pad={{ horizontal: 'medium', top: 'small', bottom: 'none' }}>
        <Box>
          <Heading margin="none" level={2} pad="none">
            User sessions
          </Heading>
        </Box>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', top: 'none', bottom: 'small' }} gap="small" align="start" fill>
        <Iframe url="https://chart-embed.service.newrelic.com/herald/f8fbf589-d45a-4f51-8f84-87cef0bd73d0?timepicker=true"
          width="100%" 
          height="100%"
          frameBorder="0"
          overflow="hidden"
          scrolling="no"
          id=""
          className=""/>
        <Tip content="View full observability...">
          <Button label="View details" 
                  onClick={() => { goNav("https://onenr.io/0qwLBbLLpj5");}}
                  secondary/>
        </Tip>
      </CardBody>
      <CardFooter pad={{horizontal: "small"}} background="light-2">
        <Tip content="Share with colleagues...">
          <Button icon={<ShareOption color="plain" />} 
                  href="mailto:?subject=EaaS Ops site...&body=https://eaas-ops.it.hpe.com"
                  hoverIndicator />
        </Tip>
      </CardFooter>
    </Card>
  );
};

export { UserSessionsComp as UserSessions };
