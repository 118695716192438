//
import { React, useContext } from 'react';
//import styled from 'styled-components';
import { Box, Grid, ResponsiveContext, Page, PageContent, Footer, PageHeader, Text } from 'grommet';
import { //CostByMonth, 
         //CostByService, 
         //CostByYear, 
         //RulesAudit, 
         UserSessions, 
         UpTime, 
         PageViewsCountry, 
         AverageResponseTimes,
         SaaSQuoteStatus, 
         HWLedQuoteStatus } from './../content/home';
import { AppFooter } from '../components';
// import {
//   convertDatesToFeatures,
//   defaultWindow,
//   formatCurrency,
//   linearRegression,
//   mean,
//   DEMO_DATE,
//   REPORT_WINDOW_MAP,
// } from './../helpers';

const HomePage = () => {

  //const [value, setValue] = React.useState('');
  //const size = useContext(ResponsiveContext);
  //const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);

  return (

    <Page background="background-back" flex="grow">

      <PageContent>

        <PageHeader title="Hello" size="medium"
          pad={{ vertical: 'small' }}
          subtitle={`Welcome to the EaaS Operations Console.`}/>

        <Content />

      </PageContent>
      
      <Footer pad={{ horizontal: 'medium', top: 'medium', bottom: 'xsmall' }} justify="center">
        <Text size="11pt" style={{fontStyle: 'italic', fontWeight: 'bold'}}>Powered by ITaaS</Text>
      </Footer>

      <AppFooter />

    </Page>
  );
};

const parentGrid = {
  columns: {
    xsmall: ['100%'],
    small: ['100%'],
    medium: [
      ['small', 'large'],
      ['small', 'large'],
    ],
    large: [
      ['small', 'medium'],
      ['small', 'medium'],
      ['small', 'medium'],
    ],
    xlarge: [
      ['medium', 'large'],
      ['medium', 'large'],
      ['medium', 'large'],
    ],
  },  
  gap: {
    xsmall: 'small',
    small: 'small',
    medium: 'small',
    large: 'medium',
    xlarge: 'medium',
  },
};

//const defaultPeriod = 'Last 30 Days';

const Content = () => {
  const size = useContext(ResponsiveContext);
  //const [period] = useState(defaultPeriod);

  return (
 
 <main>
    <Box>
      <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]}>
        <UpTime />
        <UserSessions />
        <PageViewsCountry />
      </Grid>
    </Box>

    <Box pad={{ top: 'medium'}}>
      <Grid
        rows={['medium']}
        columns={parentGrid.columns[size]}
        gap={parentGrid.gap[size]}
        areas={[
          { name: 'responsetimes', start: [0, 0], end: [2, 0] },
          { name: 'hwledquotestatus', start: [2, 0], end: [2, 0] } ]}
        fill>

        <Box gridArea="responsetimes">
          <AverageResponseTimes />
        </Box>

        <Box gridArea="hwledquotestatus"> 
          <SaaSQuoteStatus />
        </Box>
      </Grid>
    </Box> 

    <Box pad={{ top: 'medium'}} width="33%">
      <HWLedQuoteStatus />
    </Box> 

</main>
  );
};

export { HomePage as Home };

