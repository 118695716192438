import React, { useContext, useState } from 'react';
//import { useNavigate } from "react-router-dom";
//import { Box, Button, DropButton, Avatar, Header, Text, Menu, Nav, ResponsiveContext, Tip, Notification } from 'grommet';
import { Box, Button, Header, Text, Menu, Nav, ResponsiveContext, Tip, Notification } from 'grommet';
import { Hpe as HpeIcon, Slack as SlackIcon } from 'grommet-icons';
//import { UserContext, TextEmphasis } from '../helpers';



const AppHeader = () => {

  //const navigate = useNavigate();
  const size = useContext(ResponsiveContext);
  //const { user } = useContext(UserContext);
  //const [user, setUser] = useState(defaultUser);
  //const [open, setOpen] = useState();  
  const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);

  const [soonVisible, setSoonVisible] = React.useState();


function goNav(url) {
  window.location.href = url;
  //navigate("/saas");
}

function goTab(url) {
  window.open(url, '_blank').focus();
}

function goComingSoon() {
  setSoonVisible(true);
}

const items = [
  { label: 'Home', 
    onClick: () => { goNav('/') } ,
    tooltip: 'EaaS Ops home...',
    id: 'home'  
  },
  { label: 'SaaS', 
    onClick: () => { goTab('https://onenr.io/00jl59MaOQW') } ,
    tooltip: "SaaS E2E Value Chain...",
    id: 'saas' 
  },
   { label: 'IaaS', 
    onClick: () => { goComingSoon('') } ,
    tooltip: "IaaS E2E Value Chain...",
    id: 'iaas' 
  }, 
  { label: 'HW Led', 
    onClick: () => { goTab('https://onenr.io/0oQDvpd5DRy') } ,
    tooltip: "HW Led E2E Value Chain...",
    id: 'hwled' 
  },   
  { label: 'Report Issue', 
    onClick: () => { goComingSoon('') } ,
    tooltip: 'Notify us of any issue...',
    id: 'issue' 
  },  
  { 
    icon: (<SlackIcon color="plain" size="large" />),
    onClick: () => { goNav('slack://channel?team=E01LD9FH0JZ&id=C077A03GF8C') } ,
    tooltip: 'Status Central Slack support channel...',
    id: 'slack' 
  },
  //{ 
  //  icon: (<UserIcon size="medium" />),
  //  onClick: () => { goNav('/') } ,
  //  tooltip: 'User profile...',
  //  id: 'user' 
  //},    
];


  //  const contextValue = useMemo(
  //  () => ({
  //    user,
  //    setUser,
  //  }),
  //  [user],
  //);

  return (

    <Header fill="horizontal" pad={{ horizontal: 'medium' }} background="background-front">
      <Tip content="EaaS Ops home...">
        <Button onClick={() => { goNav("/"); }}>
          <Box direction="row" align="start" gap="medium" pad={{ vertical: 'small' }} responsive={false}>
            <Box height="24px" justify="center">
              <HpeIcon color="brand"/>
            </Box>
            {(!['xsmall', 'small'].includes(size) ||
              (['xsmall', 'small'].includes(size) && !focused)) && (
              <Box direction="row" gap="xsmall" wrap>
                <Text color="text-strong" weight="bold">HPE</Text>
                <Text color="text-strong">EaaS Ops</Text>
              </Box>
            )}
          </Box>
        </Button>
      </Tip>

      {soonVisible && (
        <Notification
          toast
          title="Coming soon"
          message="This observability work is in flight."
          onClose={() => setSoonVisible(false)}
        />
      )}
            
      {!['xsmall', 'small'].includes(size) ? (
        <Nav direction="row" gap="small">
          {items.map(item => (
            <Tip content={item.tooltip}>
              <Button label={item.label}
                      icon={item.icon}
                      key={item.label} 
                      onClick={item.onClick}/>
            </Tip>  
        ))}


        </Nav>

      ) : (

        <Menu label="Menu" items={items} /> 

      )}

    </Header>
  );
};

export { AppHeader as Header };
